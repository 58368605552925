import { information } from "../const/info.const";
import { decrypt, encrypt } from "../controllers/crypto.controller";

export const getCodeClient = async (promo_code, site) => {
  const host = window.location.hostname;
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let objParam = {
    site: site,
    promo_code: promo_code,
  };
  let encryptHandle = encrypt(JSON.stringify(objParam));
  let raw = JSON.stringify({
    key: encryptHandle,
  });
  let requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw,
  };
  let path = "client";
  // if (information.free_page[site].includes(host)) {
  //   if (host === "freecode.jun88.fan" || host === "code.jun88bet.us") path = "client-free";
  // }
  // if (information.live_page[site].includes(host)) {
  //   if (host === "code-live.hi88.tv") path = "client-live";
  // }
  return fetch(information.endpoint + "/" + path + "/get-code?site=" + site + "&promo_code=" + promo_code, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const addPointClient = async (player_id, promo_code, site) => {
  const host = window.location.hostname;
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let objParam = {
    site: site,
    promo_code: promo_code,
  };

  let encryptHandle = encrypt(JSON.stringify(objParam));
  let raw = JSON.stringify({
    key: encryptHandle,
  });

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw,
  };
  let path = "client";
  // if (information.free_page[site].includes(host)) {
  //   if (host === "freecode.jun88.fan" || host === "code.jun88bet.us") path = "client-free";
  // }
  // if (information.live_page[site].includes(host)) {
  //   if (host === "code-live.hi88.tv") path = "client-live";
  // }
  return fetch(information.endpoint + "/" + path + "?player_id=" + player_id + "&promo_code=" + promo_code + "&site=" + site, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};
